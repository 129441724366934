import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField, Box, Button, InputAdornment, IconButton, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useClasses from '../../useClasses';

const CssTextField = styled(TextField)({
    '& .MuiInputLabel-root': {
            color:'#fff',
            fontSize:'1.2rem',
    },
    '& label.Mui-focused': {
        color:'white',
        backgroundColor: '#C20577',
        padding: '.2rem .5rem',
        marginTop:'-.5rem',
        borderRadius: '5px',
        fontSize: '1rem'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none'
        },
        '&.Mui-focused input': {
            color: 'white'
        }
    }
})

const styles = (theme) => ({
    contentBox: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    textField: {
        backgroundColor: '#1f1f1fc9',
        color: '#fff !important',
        width: '35rem',
        borderRadius: '1rem !important',
        borderStyle: 'none',
        marginBottom: '2.5rem'
    },
    qTextField:{
        marginTop: '4rem'
    },
    input: {
        color: '#fff !important'
    },
    answerBox:{
        marginBottom: '2rem'
    },
    gridBox: {
        marginBottom: '2rem'
    },
    answerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: '0.5rem',
        padding: '.5rem 1rem',
        borderRadius: '1rem',
    },
    button: {
        padding:'.5rem 1rem',
        borderRadius: '1rem',
        backgroundColor: theme.palette.primary.light,
        marginBottom: '2rem'
    },
    questionsContainer: {
        borderRadius: '1rem',
        width: '35rem',
        marginTop: '2rem',
        padding: '20px',
        background: '#1f1f1fc9',
        color: '#fff',
        border: '2px solid rgba(154,101,189,0.9)',
        marginBottom: '5rem'
    },
    yourQsTitle: {
        color: '#fff',
        padding: '.5rem',
        border: '1px solid rgba(154,101,189,0.9)',
        marginBottom: '1rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '6px'
    },
    qAndABox: {
        textAlign: 'center',
    },
    questionsContent: {
        width:'100%',
    },
    qAndA: {
        color: '#fff !important',
        fontSize: '1.2rem', 
        marginTop: '1rem',
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textTransform: 'uppercase',
        letterSpacing: '2px'
    },
    answersContent: {
        width:'100%',
        padding: '1rem 0 3rem 0',
    },
    answerDiv: {
        margin: '1rem auto',
        textAlign: 'left',
        width: '30%'
    },
    btn: {
        backgroundColor:'#CF6790', 
        borderRadius: '2rem', 
        marginBottom: '2rem', 
        padding: '0.7rem', 
        textAlign: 'center'
    },
    border: {
        backgroundColor: 'rgba(154,101,189,0.9)',
        width: '100%',
        height: '2px'
    }
});

function SecondStep( {answerValue, setAnswerValue, answers, setAnswers, answerError, setAnswerError, question, setQuestion, questions, setQuestions} ) {
    const classes = useClasses(styles);
    


    //Triggers by clicking enter in the "Answer" input field
    const handleKeyPress = (e) => {
        handleAddAnswersClick();
    };

    //tar emot value från inputen och sparar den i ett state för Value för att lättare fylla i answers-statet
    const handleAnswerInput = (e) => {
        let targetValue = e.target.value;
        setAnswerValue(targetValue);
    };
    //funktion som sätts på knappen för answers inputen. Kollar ifall value-staten är tom först. Är den tom så sätter vi AnswerError staten till true och användaren kan inte gå vidare förrän de fully i value
    const handleAddAnswersClick = (e) => {
        if (!answerValue) {
            setAnswerError(true);
        } else {
            setAnswerError(false);
            let answersData = [...answers, answerValue];
            setAnswers(answersData);
        }
        setAnswerValue('');
    };

    //funktionen som sätta på knappen för add another question. Sätter in value för question och answers in i questions-statet för att samla flertal frågor.
    const handleAddQuestionsClick = (e) => {
        if (answers < 1) {
            setAnswerError(true);
        }else {
            setQuestions([
                ...questions,
                {
                    question: question,
                    answers: answers,
                },
                ]);
            setQuestion('');
            
        }
        setAnswers([]);
    };

    //funktioner för att ta bort våra svar och frågor.
  const deleteAnswerHandler = (i) => {
    const filterOutIndex = answers.filter((_e, idx) => {
      return i !== idx;
    });
    setAnswers(filterOutIndex);
  };

  const deleteQuestionHandler = (i) => {
    const filterOutIndex = questions.filter((_e, idx) => {
      return i !== idx;
    });
    setQuestions(filterOutIndex);
  };


    return (
        <>
        <Box className={classes.contentBox}>
            {questions < 1 ? (
                <Typography sx={{color: '#fff'}}>Don't forget to save your question!</Typography>
            ):(
                <></>
            )}
            <CssTextField
                id="outlined-basic"
                onChange={(e) => setQuestion(e.target.value)} 
                value={question} 
                label="Question" 
                variant="outlined" 
                className={`${classes.textField} ${classes.qTextField}`} 
                sx={{borderRadius: '2rem'}} 
                InputProps={{className: classes.input}}/>
            <CssTextField
                onChange={handleAnswerInput}
                value={answerValue}
                id="outlined-basic" 
                label="Answer" 
                variant="outlined" 
                className={classes.textField} 
                sx={{borderRadius: '2rem'}} 
                error={answerError}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                sx={{ paddingRight: '15px' }}
                                edge="end"
                                onClick={handleAddAnswersClick}
                            >
                                <AddCircleIcon sx={{fill: '#fff'}}/>
                            </IconButton>
                        </InputAdornment>
                    ),
                    className: classes.input
                }}
                onKeyDown={(e) => e.key === 'Enter' && handleKeyPress(e)}
            />
            <Box className={classes.answerBox}>
                {answers.map((answer, i) => (
                    <Grid item xs={12} key={i} className={classes.gridBox}>
                        <Box className={classes.answerContent}>
                            <Typography sx={{color: '#fff'}}variant="h6">{answer}</Typography>
                            <IconButton onClick={() => deleteAnswerHandler(i)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Box>
            <Button className={classes.button} onClick={handleAddQuestionsClick} variant="contained" size="small" startIcon={<AddIcon/>}>Save Question</Button>
            {questions.length >= 1  ? (
                <Box className={classes.questionsContainer}>
                    <Typography className={classes.yourQsTitle}>Your Questions</Typography>
                    {questions.map((q, i) => {
                        return (
                        <Grid item xs={12} key={i} className={classes.gridBox}>
                            <Box className={classes.qAndABox}>
                                <Box className={classes.questionsContent}>
                                    <Typography className={classes.qAndA}>{q.question}</Typography>
                                </Box>
                                <Box className={classes.answersContent}>
                                    <div className={classes.answerDiv}>
                                        {q.answers.map((a, i) => {
                                            return (
                                                <Typography key={i} className={classes.qAndA}>{a}</Typography>
                                            )
                                        })}
                                    </div>
                                </Box>
                                <IconButton className={classes.btn} onClick={() => deleteQuestionHandler(i)}>
                                    <DeleteForeverIcon sx={{color: '#fff', marginRight: '.5rem'}}/>
                                    <Typography sx={{color: '#fff'}}>Delete question</Typography>
                                </IconButton>
                                <div className={classes.border}></div>
                            </Box>
                            
                        </Grid>)
                    })}
                </Box>
            ) : (
                <></>
            )
            }
            
        </Box>
        
        
    </>
    )
}

export default SecondStep
