import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Container from '@mui/material/Container';
/* import img from './assets/Background images.svg'; */

ReactDOM.render(
  <React.StrictMode>
    <Container>
      <App />
    </Container>
  </React.StrictMode>,
  document.getElementById('root')
);
