import { Typography, Grid, Chip } from '@mui/material/';

import useClasses from '../../../useClasses';
//import { styled } from '@mui/material/styles';
import getColors from '../GetColors';
import getBoxShadow from '../GetBoxShadow';
import { motion } from 'framer-motion';

//Style
/* const Item = styled('div')(({ theme }) => ({

  },
})); */

const styles = (theme) => ({
  resultContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10rem',
    paddingBottom: '10rem',
    '@media (max-width:1280px)': {
      paddingTop: '4rem',
      paddingBottom: '5rem',
    },
  },
  resultGrid: {
    padding: '50px 150px',
    maxHeight: '100%',
  },
  question: {
    fontSize: '24px',
    padding: '5px 0 30px 0',
    textAlign: 'left',
    color: '#fff',
    '@media (max-width:1280px)': {
      fontSize: '1.5rem',
      paddingBottom: '10px',
    },
  },
  chipBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  chip: {
    '@media (max-width:1280px)': {
      fontSize: '0.5rem',
      height: '20px',
    },
  },
  resultVoteChip: {
    border: '1px solid rgba(154,101,189,0.9)',
    boxShadow:
      ' 0px 4px 4px 0px rgba(154,101,189,1), 0px 4px 4px 4px rgba(154,101,189,0.25)',
    color: '#fff',
    '@media (max-width:1280px)': {
      fontSize: '0.6rem',
      height: '25px',
    },
  },
  contentTitle: {
    paddingBottom: '2px',
    textAlign: 'center',
    fontWeight: 700,
    color: '#fff',
    '@media (max-width:1280px)': {
      fontSize: '1.2rem',
      margin: '0.5rem 0',
    },
  },
  contentBy: {
    textAlign: 'left',
    fontWeight: 400,
    color: '#fff',
    fontSize: 14,
    paddingBottom: '30px',
    '@media (max-width:1280px)': {
      fontSize: '0.7rem',
      paddingBottom: '10px',
    },
  },
  contentCreator: {
    '@media (max-width:1280px)': {
      fontSize: '1.1rem',
    },
  },
  contentDate: {
    '@media (max-width:1280px)': {
      fontSize: '0.7rem',
    },
  },
  answerBox: {
    padding: '15px',
    border: '3px solid',
    marginBottom: '3rem',
    borderRadius: '10px',
    background: '#1f1f1f2f',
    '@media (max-width:1280px)': {
      marginBottom: '1.5rem',
      padding: '5px 15px',
    },
  },
  answerAndCount: {
    paddingTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '6px',
    textAlign: 'left',
    fontWeight: 700,
    color: '#fff',
  },
  answer: {
    '@media (max-width:1280px)': {
      fontSize: '14px',
    },
  },
  voteBar: {
    height: '12px',
    width: '100%',
    borderRadius: '10px',
    marginBottom: '30px',
    position: 'relative',
    backgroundColor: '#1f1f1fc9',
    '@media (max-width:1280px)': {
      height: '7px',
    },
  },
  insideVoteBar: {
    height: '100%',
    position: 'absolute',
    borderRadius: '10px',
    marginBottom: '30px',
    '@media (max-width:1280px)': {
      height: '7px',
    },
  },
});

const ResultsLayout = ({ title, creator, qAndRes, votesLength, polls }) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.resultContainer}>
      <Grid container item md={8} lg={9} xl={12} className={classes.resultGrid}>
        <div className={classes.chipVoteTitle}>
          {polls.anonymous === true ? (
            <Chip
              sx={{
                marginRight: '5px',
                borderRadius: '5px',
                marginBottom: '3px',
              }}
              label="ANONYMOUS"
              size="small"
              color="secondary"
              className={classes.chip}
            />
          ) : null}
          {polls.privatePoll === true ? (
            <Chip
              sx={{ borderRadius: '5px', marginBottom: '3px' }}
              label="PRIVATE"
              size="small"
              color="primary"
              className={classes.chip}
            />
          ) : (
            <Chip
              sx={{ borderRadius: '5px', marginBottom: '3px' }}
              label="PUBLIC"
              size="small"
              color="primary"
              className={classes.chip}
            />
          )}
        </div>
        <div className={classes.chipBox}>
          <Typography variant="h4" className={classes.contentTitle}>
            {title}
          </Typography>
          <Chip
            label={`${votesLength} Votes`}
            variant="outlined"
            className={classes.resultVoteChip}
          />
        </div>
        <Typography variant="h6" className={classes.contentBy}>
          Asked by{' '}
          <span className={classes.contentCreator}>{creator.creatorName}</span>{' '}
          {new Date(creator.createdAt._seconds * 1000).toLocaleDateString(
            'en-gb'
          )}
        </Typography>
        {/* Bättre lösning än nested loops? */}
        {qAndRes.map((resultArr, index) => {
          return (
            <Grid key={index} item xs={12}>
              {/* <Item> */}
              <div>
                <Typography variant="h4" className={classes.question}>
                  {resultArr.question.question}
                </Typography>
              </div>

              {resultArr.question.answers.map((answer, i) => {
                let counter = 0;
                for (const obj of resultArr.result) {
                  if (obj.answer === answer) counter++;
                }
                let percent = Math.round((counter / votesLength) * 100);

                return (
                  <motion.div
                    key={i}
                    initial={{ x: -800, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.4,
                      ease: 'easeOut',
                      delay: i * 0.2,
                    }}
                  >
                    <div
                      style={{
                        borderColor: getColors(i),
                        boxShadow: getBoxShadow(i),
                      }}
                      className={classes.answerBox}
                    >
                      <div className={classes.answerAndCount}>
                        <Typography variant="h6" className={classes.answer}>
                          {answer}
                        </Typography>
                        <Typography variant="h6" className={classes.answer}>
                          {' '}
                          {counter} Votes{' '}
                          {isNaN(percent) ? (
                            ''
                          ) : (
                            <span
                              style={{ paddingLeft: '20px' }}
                              className={classes.answer}
                            >
                              {percent}%{' '}
                            </span>
                          )}
                        </Typography>
                      </div>
                      <div className={classes.voteBar}>
                        <div
                          style={{
                            width: `${percent}%`,
                            backgroundColor: getColors(i),
                          }}
                          className={classes.insideVoteBar}
                        ></div>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
              {/*   </Item> */}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
export default ResultsLayout;
