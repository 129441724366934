import React from 'react';
import useClasses from '../useClasses';
import { Box, Typography, Button } from '@mui/material';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import { motion } from 'framer-motion';

const styles = (theme) => ({
  startContainer: {
    /* height: '100vh', */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hero: {
    textAlign: 'center',
    marginTop: '25rem',
    '@media (max-width:1280px)': {
      marginTop: '10rem',
    },
  },

  title: {
    color: '#fff !important',
    '@media (max-width:1280px)': {
      fontSize: '3rem',
    },
  },
  button: {
    backgroundColor: '#9A65BD !important',
    marginTop: '2rem',
    '&:hover': {
      backgroundColor: '#56386A !important',
    },
  },
});

function StartPage() {
  const classes = useClasses(styles);

  //Animation
  const container = {
    hidden: { y: -800, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: { duration: 1.3, ease: 'easeOut', staggerChildren: 0.5 },
    },
  };
  const btnContainer = {
    hidden: { x: -800, opacity: 0 },
    show: {
      x: 0,
      opacity: 1,
      transition: { duration: 1.3, ease: 'easeOut', staggerChildren: 0.5 },
    },
  };

  return (
    <div className={classes.background}>
      <div className={classes.startContainer}>
        <Box className={classes.hero}>
          <motion.div variants={container} initial="hidden" animate="show">
            <Typography variant="h2" className={classes.title}>
              Making decisions
            </Typography>

            <Typography variant="h2" className={classes.title}>
              together easy
            </Typography>
          </motion.div>
          <motion.div variants={btnContainer} initial="hidden" animate="show">
            <Button
              variant="contained"
              size="large"
              endIcon={<EqualizerRoundedIcon />}
              className={classes.button}
              href="/createpoll"
            >
              Create Poll
            </Button>
          </motion.div>
        </Box>
      </div>
    </div>
  );
}

export default StartPage;
