import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import Navbar from './Components/Navbar';
import StartPage from './Components/StartPage';
import PersonalPolls from './Components/PersonalPolls';
import PublicPolls from './Components/PublicPolls';
import CreatePoll from './Components/CreatePoll/CreatePoll';
import Vote from './Components/Vote/Vote';
import Results from './Components/Results/Results';

const theme = createTheme({
  palette: {
    primary: {
      main: '#55398B',
      light: '#9A65BD',
    },
    secondary: {
      main: '#CF6790',
      dark: '#C20577',
    },
  },

  typography: {
    fontFamily: 'Nunito',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },

  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          color: '#EEEEEE',
          text: {
            fill: '#616161',
            fontWeight: 'bold',
          },
          '&.Mui-active': {
            text: {
              fill: '#fff',
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#fff !important',
          fontSize: '1rem'
        },
      },
    },
  },
});

function App() {
  return (
        <ThemeProvider theme={theme}>
          <Router>
            <Navbar />
            <Route path="/*"></Route>
            <Switch>
              <Route exact path="/">
                <StartPage />
              </Route>
              <Route path="/personalpolls">
                <PersonalPolls />
              </Route>
              <Route path="/publicpolls">
                <PublicPolls />
              </Route>
              <Route path="/createpoll">
                <CreatePoll />
              </Route>
              <Route path="/vote/:id">
                <Vote />
              </Route>
              <Route path="/:id/results">
                <Results />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
  );
}

export default App;
