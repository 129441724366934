import React from 'react';
import {
  TextField,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import useClasses from '../../useClasses';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: '#fff',
    fontSize: '1.2rem',
  },
  '& label.Mui-focused': {
    color: 'white',
    backgroundColor: '#C20577',
    padding: '.2rem .5rem',
    marginTop: '-.5rem',
    borderRadius: '5px',
    fontSize: '1rem',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused input': {
      color: 'white',
    },
  },
});

const styles = (theme) => ({
  contentBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    backgroundColor: '#1f1f1fc9',
    color: '#fff !important',
    width: '35rem',
    borderRadius: '1rem !important',
    borderStyle: 'none',
    marginTop: '4rem',
  },
  input: {
    color: '#fff !important',
  },
  formControl: {
    width: '30rem',
    margin: '2rem auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formGroup: {
    flexDirection: 'row',
    margin: '1rem',
    justifyContent: 'space-around',
    width: '100%',
    color: '#fff',
  },
});

function FirstStep({ title, setTitle, state, setState, titleError }) {
  const classes = useClasses(styles);

  // Checking anonymous and privatepoll if they are switched, if they are, setting the state to true
  const handleSwitch = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <Box className={classes.contentBox}>
        <CssTextField
          id="outlined-basic"
          label="Subject"
          variant="outlined"
          className={classes.textField}
          sx={{ borderRadius: '2rem' }}
          InputProps={{ className: classes.input }}
          onChange={(e) => setTitle(e.target.value)}
          error={titleError}
        />
      </Box>
      <FormControl
        component="fieldset"
        variant="standard"
        className={classes.formControl}
      >
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={state.anonymous}
                onChange={handleSwitch}
                name="anonymous"
              />
            }
            label="Anonymous Voters"
          />
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={state.privatePoll}
                onChange={handleSwitch}
                name="privatePoll"
              />
            }
            label="Private Poll"
          />
        </FormGroup>
      </FormControl>
    </>
  );
}

export default FirstStep;
