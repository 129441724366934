import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material/';
import loader from '../../assets/Pulse-1s-200px.svg';
import ResultsLayout from './layout/ResultsLayout';

const Results = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [creator, setCreator] = useState({});
  const [qAndRes, setQAndRes] = useState([]);
  const [votesLength, setVotesLength] = useState(null);
  const [loading, setLoading] = useState(false);
  const [polls, setPolls] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/polls/${id}/result`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('adal.idtoken'),
            },
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          //console.log(data);
          setTitle(data.title);
          setPolls(data);
          console.log(data);
          setCreator({
            creatorName: data.creatorName,
            createdAt: data.createdAt,
          });
          setQAndRes(data.questionsAnswersResults);
          setVotesLength(data.votesLength);
          setTimeout(() => {
            setLoading(true);
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
    })();
    //eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <ResultsLayout
        title={title}
        creator={creator}
        qAndRes={qAndRes}
        votesLength={votesLength}
        polls={polls}
      />
    );
  } else {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={loader} alt="" />
      </Box>
    );
  }
};

export default Results;
