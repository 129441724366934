import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useClasses from '../../useClasses';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import {
  Button,
  Typography,
  Grid,
  Chip,
  Radio,
  Modal,
  Fade,
  Backdrop,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@mui/material/';
import Check from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import loader from '../../assets/Pulse-1s-200px.svg';
import { Helmet } from 'react-helmet';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: 330,
  backgroundColor: '#eeeeee !important',
  p: 4,
  borderRadius: '10px',
  border: '3px solid rgba(154,101,189,1) !important',
  color: '#000 !important',
};

const Item = styled('div')(({ theme }) => ({
  padding: '20px',
  borderRadius: '5px',
  '@media (max-width:1280px)': {
    padding: '5px 10px',
  },
}));

const styles = (theme) => ({
  voteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10rem 0',
    '@media (max-width:1280px)': {
      padding: '4rem 0 5rem 0',
    },
  },
  voteGrid: {
    padding: '0 5rem 0 5rem',
  },
  chipBox: {
    width: '100%',
    display: 'flex',
  },
  btnBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  toResult: {
    '@media (max-width:1280px)': {
      width: '160px !important',
      fontSize: '0.6rem !important',
      height: '30px !important',
    },
  },
  chip: {
    '@media (max-width:1280px)': {
      fontSize: '0.5rem',
      height: '20px',
    },
  },
  title: {
    padding: '10px 0 10px 0',
    textAlign: 'left',
    color: '#fff',
    '@media (max-width:1280px)': {
      fontSize: '1.2rem',
    },
  },
  questionGrid: {
    width: '100vw',
  },
  voteQuestion: {
    color: '#fff',
    paddingBottom: '1rem',
    fontSize: '1.5rem',
    '@media (max-width:1280px)': {
      fontSize: '1.4rem',
    },
  },
  answerItem: {
    background: '#1f1f1fc9',
    border: '2px solid rgba(154,101,189,1)',
    marginBottom: '30px',
    '&:hover': {
      boxShadow:
        ' 0px 6px 4px 0px rgba(154,101,189,1), 0px 7px 4px 4px rgba(154,101,189,0.25)',
    },
    '@media (max-width:1280px)': {
      marginBottom: '15px',
    },
  },

  askedby: {
    textAlign: 'left',
    fontWeight: 400,
    color: '#fff',

    paddingBottom: '30px',
    '@media (max-width:1280px)': {
      fontSize: '0.7rem',
      paddingBottom: '10px',
    },
  },
  creator: {
    fontWeight: 700,
    '@media (max-width:1280px)': {
      fontSize: '1.1rem',
    },
  },
  voteBox: {
    display: 'flex',
    alignItems: 'center',
  },
  resultBtn: {
    marginTop: 45,
  },
  submitBtn: {
    marginTop: '2rem',
    padding: 15,
    '@media (max-width:1280px)': {
      width: '150px !important',
      fontSize: '0.6rem !important',
      height: '40px !important',
    },
  },
  submitBtnDisable: {
    marginTop: '2rem',
    padding: 15,
    background: '#CF6790 !important',
    color: '#fff !important',
    '@media (max-width:1280px)': {
      width: '150px !important',
      fontSize: '0.6rem !important',
      height: '40px !important',
    },
  },
  remindText: {
    paddingTop: '1rem',
    color: '#fff',
    '@media (max-width:1280px)': {
      paddingTop: '0.6rem',
      fontSize: '1rem',
    },
  },
  modalTitle: {
    fontWeight: 700,
    paddingBottom: 15,
  },
  modalP: {
    fontWeight: 600,
  },
});

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#fff',
          fontSize: '1.3rem !important',
          '@media (max-width:1280px)': {
            fontSize: '1rem !important',
          },
        },
      },
    },
  },
});

//const idAndAnswerValue = {};

function Vote() {
  const classes = useClasses(styles);
  const [polls, setPolls] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [idAndAnswerValue, setIdAndAnswerValue] = useState({});
  const [creator, setCreator] = useState({});
  const radioRef = useRef([]);

  //Functions
  const handleChange = (idx, i, question) => {
    console.log(radioRef.current[idx][i]);
    console.log(radioRef.current);
    const _idAndAnswerValue = {
      ...idAndAnswerValue,
    };

    _idAndAnswerValue[question.id] = radioRef.current[idx][i];

    setIdAndAnswerValue(_idAndAnswerValue);
  };

  const handleSubmit = (e) => {
    handleOpen();
    postResult();
  };

  const handleClick = (e) => {
    history.push(`/${id}/results`);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validate = () => {
    return questions.length !== Object.keys(idAndAnswerValue).length;
  };

  //Get
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/polls/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('adal.idtoken'),
            },
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          setPolls(data.poll);
          setCreator({
            creatorName: data.poll.creatorName,
            createdAt: data.poll.createdAt,
          });

          setQuestions(data.questions);

          setTimeout(() => {
            setLoading(true);
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
    })();
    //eslint-disable-next-line
  }, []);

  //Post
  const postResult = () => {
    const data = {
      result: idAndAnswerValue,
    };
    console.log(data);
    fetch(process.env.REACT_APP_API_URL + `/polls/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('adal.idtoken'),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success: ', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  if (loading) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{polls && polls.title}</title>
          <meta name="description" content={`Quizzical ` + polls.title} />
        </Helmet>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 1000,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <CheckCircleIcon
                sx={{ fontSize: 60, paddingBottom: '1rem' }}
                color="secondary"
              />
              <Typography className={classes.modalTitle} variant="h4">
                Thanks for your <br /> vote!
              </Typography>
              <Typography className={classes.modalP} variant="h6">
                Now that you’ve voted why not look at the result?
              </Typography>
              <Button
                variant="contained"
                endIcon={<ChevronRightIcon />}
                className={classes.resultBtn}
                onClick={handleClick}
              >
                Results
              </Button>
            </Box>
          </Fade>
        </Modal>

        <div className={classes.voteContainer}>
          <Grid item md={8} xl={12} container className={classes.voteGrid}>
            <Grid item md={12}>
              <div>
                <div className={classes.chipBox}>
                  {polls.anonymous === true ? (
                    <Chip
                      sx={{ marginRight: '5px', borderRadius: '5px' }}
                      label="ANONYMOUS"
                      size="small"
                      color="secondary"
                      className={classes.chip}
                    />
                  ) : null}
                  {polls.privatePoll === true ? (
                    <Chip
                      sx={{ borderRadius: '5px' }}
                      label="PRIVATE"
                      size="small"
                      color="primary"
                      className={classes.chip}
                    />
                  ) : (
                    <Chip
                      sx={{ borderRadius: '5px' }}
                      label="PUBLIC"
                      size="small"
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                  <div className={classes.btnBox}>
                    <Button
                      onClick={handleClick}
                      endIcon={<ArrowForwardIcon />}
                      variant="contained"
                      className={classes.toResult}
                    >
                      Continue to result
                    </Button>
                  </div>
                </div>
                <Typography variant="h4" className={classes.title}>
                  {polls.title}
                </Typography>
                <Typography variant="h6" className={classes.askedby}>
                  Asked by{' '}
                  <span className={classes.creator}>{creator.creatorName}</span>{' '}
                  {new Date(
                    creator.createdAt._seconds * 1000
                  ).toLocaleDateString('en-gb')}
                </Typography>
              </div>
            </Grid>
            <FormControl>
              {questions.map((question, idx) => {
                /* console.log(question) */

                return (
                  <Grid item xs={12} key={idx} className={classes.questionGrid}>
                    <Typography variant="h4" className={classes.voteQuestion}>
                      {question.question}
                    </Typography>
                    <RadioGroup
                      ref={(radioRef.current[idx] = question.answers)}
                      name="radio-buttons-group"
                      required
                    >
                      {question.answers.map((answer, i) => (
                        <motion.div
                          key={i}
                          initial={{ x: -800, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{
                            duration: 0.3,
                            ease: 'easeOut',
                            delay: i * 0.1,
                          }}
                        >
                          <Item
                            className={classes.answerItem}
                            onClick={() => handleChange(idx, i, question)}
                          >
                            <div className={classes.voteBox}>
                              <ThemeProvider theme={theme}>
                                <FormControlLabel
                                  value={answer}
                                  //ref={() => (radioRef.current[idx][i] = answer)}
                                  control={
                                    <Radio
                                      checked={
                                        idAndAnswerValue[question.id] === answer
                                      }
                                      sx={{
                                        color: '#9A65BD',
                                        '&.Mui-checked': {
                                          color: '#9A65BD',
                                        },
                                      }}
                                    />
                                  }
                                  label={answer}
                                  color="secondary"
                                  className={classes.answer}
                                />
                              </ThemeProvider>
                            </div>
                          </Item>
                        </motion.div>
                      ))}
                    </RadioGroup>
                  </Grid>
                );
              })}
            </FormControl>

            <Grid sx={{ textAlign: 'center' }} item xs={12}>
              <Button
                onClick={handleSubmit}
                startIcon={<Check />}
                variant="contained"
                className={
                  !validate() ? classes.submitBtn : classes.submitBtnDisable
                }
                color="secondary"
                disabled={validate()}
              >
                Submit Your Vote
              </Button>
              {validate() ? (
                <Typography variant="h6" className={classes.remindText}>
                  Don't forget to vote on all questions before submitting!
                </Typography>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={loader} alt="" />
      </Box>
    );
  }
}
export default Vote;
