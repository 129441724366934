import React from 'react'
import useClasses from '../../useClasses';
import { 
Box, 
Stepper,
Step,
StepLabel,
Button
} from '@mui/material';
//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
//Components
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';

const styles = (theme) => ({
    button: {
        borderRadius: '1rem'
      },
    pollIcon: {
        color: theme.palette.primary.light,
        fontSize: '3.5rem',
        width: '100%',
        marginBottom: '3rem'
    }
});


const steps = ['Choose subject', 'Add questions & answers', 'Review and complete'];



function StepperFunc({handleSubmit, title, setTitle, titleError, setTitleError, state, setState, answerValue, setAnswerValue, answers, setAnswers, answerError, setAnswerError, question, setQuestion, questions, setQuestions}) {
    const classes = useClasses(styles);
    const [activeStep, setActiveStep] = React.useState(0);


    const handleNext = () => {
        switch(activeStep){
            case 0:
                if (title === ''){
                    setTitleError(true);
                } else{
                    setTitleError(false);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            case 1: 
                if (questions.length < 1){
                    return
                } else{
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;
            case 2:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                if( activeStep === steps.length - 1 ){
                    handleSubmit()
                }
                break;
            default:
                console.log('Hello cases')
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(step) {
        switch (step) {
            case 0: 
                return <FirstStep 
                title={title}
                setTitle={setTitle}
                titleError={titleError}
                setTitleError={setTitleError}
                state={state}
                setState={setState}
                />;
            case 1:
                return <SecondStep
                answerValue={answerValue}
                setAnswerValue={setAnswerValue}
                answers={answers}
                setAnswers={setAnswers}
                answerError={answerError}
                setAnswerError={setAnswerError}
                question={question}
                setQuestion={setQuestion}
                questions={questions}
                setQuestions={setQuestions}
                />;
            case 2: 
                return <ThirdStep
                state={state}
                title={title}
                questions={questions}
                />
            default:
                return "Unknown step"
        }
    }

    return (
        <Box sx={{ width: '100%', marginTop: '10rem'}}>
            <EqualizerRoundedIcon className={classes.pollIcon}/>
            <Stepper activeStep={activeStep} alternativeLabel >
                {steps.map((label) => {
                return (
                    <Step key={label} >
                        <StepLabel>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                </React.Fragment>
            ) : (
                <React.Fragment>
                
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginTop: '1rem' }}>
                    <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="contained"
                    startIcon={<ArrowBackIcon/>}
                    className={classes.button}
                    size="large"
                    >
                    Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext} variant="contained" endIcon={<ArrowForwardIcon/>} className={classes.button} size="large">
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
                {getStepContent(activeStep)}
                </React.Fragment>
            )}
    </Box>
    )
}

export default StepperFunc
