import React from 'react';
import useClasses from '../../useClasses';
import { Typography,  Box, Grid,} from '@mui/material'


const styles = (theme) => ({
    container: {
        paddingBottom: '2rem',
    },
    happyPoll: {
        margin: '3rem 0',
        color: '#fff',
        textAlign:'center'
    },
    gridBox: {
        marginBottom: '2rem'
    },
    title: {
        color: '#fff',
        fontSize: '2rem',
        textAlign: 'center',
        marginBottom: '2rem',
        letterSpacing: '2px',
    },
    questionsContainer: {
        borderRadius: '1rem',
        width: '35rem',
        padding: '20px',
        background: '#1f1f1fc9',
        color: '#fff',
        border: '2px solid rgba(154,101,189,0.9)',
        margin: '2rem auto 5rem auto',
    },
    yourQsTitle: {
        color: '#fff',
        padding: '.5rem',
        border: '1px solid rgba(154,101,189,0.9)',
        marginBottom: '1rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '6px'
    },
    qAndABox: {
        textAlign: 'center',
    },
    questionsContent: {
        width:'100%',
    },
    qAndA: {
        color: '#fff !important',
        fontSize: '1.2rem', 
        marginTop: '1rem',
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textTransform: 'uppercase',
        letterSpacing: '2px'
    },
    answersContent: {
        width:'100%',
        padding: '1rem 0 3rem 0',
    },
    answerDiv: {
        margin: '1rem auto',
        textAlign: 'left',
        width: '30%'
    },
    btn: {
        backgroundColor:'#CF6790', 
        borderRadius: '2rem', 
        marginBottom: '2rem', 
        padding: '0.7rem', 
        textAlign: 'center'
    },
    border: {
        backgroundColor: 'rgba(154,101,189,0.9)',
        width: '100%',
        height: '2px'
    }
});


function ThirdStep({questions, title, state}) {
    const classes = useClasses(styles);
    console.log(questions)
    return (
        <div className={classes.container}>
            <Typography variant='h5'className={classes.happyPoll}>Are you happy with your poll? Click finish!</Typography>
            <Box className={classes.questionsContainer}>
            <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.yourQsTitle}>Your Questions</Typography>
                {questions.map((q, i) => {
                    return (
                    <Grid item xs={12} key={i} className={classes.gridBox}>
                        <Box className={classes.qAndABox}>
                            <Box className={classes.questionsContent}>
                                <Typography className={classes.qAndA}>{q.question}</Typography>
                            </Box>
                            <Box className={classes.answersContent}>
                                <div className={classes.answerDiv}>
                                    {q.answers.map((a, i) => {
                                        return (
                                            <Typography key={i} className={classes.qAndA}>{a}</Typography>
                                        )
                                    })}
                                </div>
                            </Box>
                            <div className={classes.border}></div>
                        </Box> 
                    </Grid>)
                })}
            </Box>
        </div>
    )
}

export default ThirdStep
