import React, { useEffect, useState } from 'react';
//import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router';
import { styled } from '@mui/material/styles';
import useClasses from '../useClasses';
import { Typography, Grid, Chip, Box } from '@mui/material/';
import BoltIcon from '@mui/icons-material/Bolt';
import loader from '../assets/Pulse-1s-200px.svg';
import { Helmet } from 'react-helmet';

//Style
const Item = styled('div')(({ theme }) => ({
  marginBottom: '2.5rem',
  cursor: 'pointer',
  '@media (max-width:1280px)': {
    marginBottom: '1.5rem',
  },
}));

const styles = (theme) => ({
  publicContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10rem',
    paddingBottom: '10rem',
    '@media (max-width:1280px)': {
      paddingTop: '3rem',
      paddingBottom: '5rem',
    },
  },
  publicGrid: {
    padding: '50px 150px',
    maxHeight: '100%',
  },
  publicTitle: {
    paddingBottom: '10px',
    textAlign: 'center',
    color: '#fff',
    '@media (max-width:1280px)': {
      fontSize: '2.2rem',
    },
  },
  underTitle: {
    paddingBottom: '4rem',
    textAlign: 'center',
    fontWeight: 700,
    color: '#fff',
    '@media (max-width:1280px)': {
      paddingBottom: '1.5rem',
    },
  },
  titleAndCreator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'left',
    fontSize: '1rem',
  },
  chipAndDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chip: {
    '@media (max-width:1280px)': {
      fontSize: '0.5rem',
      height: '20px',
    },
  },
  voteChip: {
    border: '1px solid rgba(154,101,189,0.9)',
    boxShadow:
      ' 0px 4px 4px 0px rgba(154,101,189,1), 0px 4px 4px 4px rgba(154,101,189,0.25)',
    color: '#fff',
    '@media (max-width:1280px)': {
      fontSize: '0.6rem',
      height: '25px',
    },
  },
  content: {
    borderRadius: '10px',
    padding: '20px',
    background: '#1f1f1fc9',
    color: '#fff',
    border: '2px solid rgba(154,101,189,0.9)',
    '&:hover': {
      boxShadow:
        ' 0px 6px 4px 0px rgba(154,101,189,1), 0px 7px 4px 4px rgba(154,101,189,0.25)',
    },
    '@media (max-width:1280px)': {
      padding: '15px',
    },
  },
  contentTitle: {
    fontWeight: 700,
    margin: '1rem 0 1rem 0',
    '@media (max-width:1280px)': {
      fontSize: '1.2rem',
      margin: '0.5rem 0',
    },
  },
  contentBy: {
    '@media (max-width:1280px)': {
      fontSize: '0.7rem',
    },
  },
  contentCreator: {
    '@media (max-width:1280px)': {
      fontSize: '1.1rem',
    },
  },
  contentDate: {
    '@media (max-width:1280px)': {
      fontSize: '0.7rem',
    },
  },
  itemContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function PublicPolls() {
  const classes = useClasses(styles);
  const history = useHistory();
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(false);

  // hämtar vår data från API:et med GET. Sparar ner datan och översätter till json. Sparar vår data inom vårt lokala state.
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/polls/public',
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('adal.idtoken'),
            },
          }
        );
        const data = await response.json();
        console.log(data);
        if (response.status === 200) {
          setPolls(data);

          setTimeout(() => {
            setLoading(true);
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  if (loading) {
    return (
      <div className={classes.publicContainer}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Public Polls</title>
        </Helmet>
        <Grid
          container
          item
          md={8}
          lg={9}
          xl={12}
          className={classes.publicGrid}
        >
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 600 }}
              variant="h3"
              className={classes.publicTitle}
            >
              <BoltIcon
                fontSize="large"
                color="secondary"
                sx={{ transform: 'scaleX(-1) rotate(15deg)' }}
              />
              Public Polls
              <BoltIcon
                fontSize="large"
                color="primary"
                sx={{ transform: 'scaleX(1) rotate(15deg)' }}
              />
            </Typography>
            <Typography className={classes.underTitle}>
              Let your colleagues know what you think!
            </Typography>
          </Grid>

          {polls.map((poll) => (
            <Grid
              onClick={() => history.push(`/vote/${poll.id}`)}
              item
              xs={12}
              key={poll.id}
            >
              <Item className={classes.content}>
                <div className={classes.itemContent}>
                  <div className={classes.titleAndCreator}>
                    <div style={{ flexDirection: 'row' }}>
                      <Chip
                        sx={{ marginRight: '5px', borderRadius: '5px' }}
                        label="PUBLIC"
                        size="small"
                        color="primary"
                        className={classes.chip}
                      />
                      {poll.anonymous === true ? (
                        <Chip
                          sx={{ borderRadius: '5px' }}
                          label="ANONYMOUS"
                          size="small"
                          color="secondary"
                          className={classes.chip}
                        />
                      ) : null}
                    </div>
                    <Typography variant="h5" className={classes.contentTitle}>
                      {poll.title}
                    </Typography>
                    <Typography variant="h6" className={classes.contentBy}>
                      {' '}
                      created by:{' '}
                      <span
                        style={{ fontWeight: 700 }}
                        className={classes.contentCreator}
                      >
                        {poll.creatorName}
                      </span>
                    </Typography>
                  </div>
                  <div className={classes.chipAndDate}>
                    <Chip
                      label={'Votes ' + poll.voterCount}
                      variant="outlined"
                      className={classes.voteChip}
                    />
                    <Typography
                      sx={{ fontSize: '1.1rem' }}
                      variant="h6"
                      className={classes.contentDate}
                    >
                      {new Date(
                        poll.createdAt._seconds * 1000
                      ).toLocaleDateString('en-gb')}
                    </Typography>
                  </div>
                </div>
              </Item>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Public Polls</title>
        </Helmet>
        <Box
          sx={{
            marginTop: '25rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={loader} alt="" />
        </Box>
      </>
    );
  }
}

export default PublicPolls;
