import React, { useState } from 'react';
import { useHistory } from 'react-router';
import useClasses from '../../useClasses';
import {
  Button,
  Typography,
  IconButton,
  Modal,
  Box,
  Backdrop,
  Fade,
} from '@mui/material/';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StepperFunc from './StepperFunc';
import { Helmet } from 'react-helmet';

//Style for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height: 360,
  backgroundColor: '#eeeeee !important',
  p: 4,
  borderRadius: '10px',
  border: '3px solid rgba(154,101,189,1) !important',
  color: '#000 !important',
};

const styles = (theme) => ({
  modalContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  linkBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    padding: '5px 0',
    marginBottom: '0.4rem',
  },
  copiedText: {
    textAlign: 'center',
    padding: '12px 0',
  },
});

const CreatePoll = () => {
  const classes = useClasses(styles);
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [state, setState] = useState({
    anonymous: false,
    privatePoll: false,
  });
  const [answerValue, setAnswerValue] = useState('');
  const [answers, setAnswers] = useState([]);
  const [answerError, setAnswerError] = useState(false);
  const [question, setQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  //Modal states
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState('');
  const [voteId, setVoteId] = useState('');
  const [copiedText, setCopiedText] = useState(false);
  //const { id } = useParams();

  /*********** THIS IS FOR MODAL *************/
  //funktion för att stänga vår modul.
  const handleClose = () => setOpen(false);

  //funktion för att skicka användaren till sin skapade poll
  function handleClick(e) {
    history.push(`${voteId}/results/`);
  }

  //funktion för att kopiera länken för den skapade pollen
  const copiedTextHandler = (e) => {
    setCopiedText(true);
    navigator.clipboard.writeText(link);
  };

  const handleSubmit = (e) => {
    postPoll();
  };

  const postPoll = () => {
    const data = {
      title,
      privatePoll: state.privatePoll,
      anonymous: state.anonymous,
      questions,
    };

    fetch(process.env.REACT_APP_API_URL + '/polls', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('adal.idtoken'),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data.id);
        setLink(process.env.REACT_APP_REDIRECT_URL + `/vote/${data.id}`);
        setOpen(true);
        setVoteId(data.id);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Poll</title>
        {/* <meta name="description" content="App Description" /> */}
      </Helmet>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={classes.modalContent}>
              <CheckCircleIcon
                sx={{ fontSize: 50, paddingBottom: '1rem' }}
                color="secondary"
              />
              <Typography sx={{ fontWeight: 700 }} variant="h5">
                Yay! Your poll has been created.
              </Typography>
              <Typography sx={{ paddingBottom: '3rem' }}>
                Copy url below to share your poll
              </Typography>

              <div className={classes.linkBox}>
                <Typography>{link}</Typography>
                <div>
                  <IconButton
                    color="secondary"
                    onClick={(e) => {
                      copiedTextHandler();
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              </div>

              <Button
                size="large"
                color="secondary"
                variant="contained"
                sx={{
                  width: '100%',
                  padding: '12px 0',
                }}
                onClick={handleClick}
              >
                Continue to result
              </Button>
              {copiedText === true ? (
                <div className={classes.copiedText}>
                  <Typography variant="h6">
                    Link copied to the clipboard
                  </Typography>
                </div>
              ) : null}
            </div>
          </Box>
        </Fade>
      </Modal>
      <StepperFunc
        handleSubmit={handleSubmit}
        title={title}
        setTitle={setTitle}
        titleError={titleError}
        setTitleError={setTitleError}
        state={state}
        setState={setState}
        answerValue={answerValue}
        setAnswerValue={setAnswerValue}
        answers={answers}
        setAnswers={setAnswers}
        answerError={answerError}
        setAnswerError={setAnswerError}
        question={question}
        setQuestion={setQuestion}
        questions={questions}
        setQuestions={setQuestions}
      ></StepperFunc>
    </>
  );
};

export default CreatePoll;
