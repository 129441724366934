import React from 'react';
import { NavLink } from 'react-router-dom';
import { authContext } from '../utils/adalConfig';
import {
  AppBar,
  Toolbar,
  Button,
  Fab,
  Zoom,
  useScrollTrigger,
} from '@mui/material';
import useClasses from '../useClasses';
import logo from '../assets/geshdologoWhite.png';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const styles = (theme) => ({
  navbar: {
    backgroundColor: '#131313',
    padding: '20px 0',
    position: 'fixed',
    '@media (max-width:1280px)': {
      padding: '10px 0',
    },
  },
  link: {
    color: '#fff',
    margin: '0px 50px',
    textDecoration: 'none',
    fontFamily: 'Nunito',
    fontSize: '1.25rem',
    fontWeight: '600',
    flexWrap: 'nowrap',
    '&:hover': {
      color: '#CF6790',
    },
    '@media (max-width:1280px)': {
      fontSize: '0.9rem',
    },
    '&.active': {
      fontWeight: 'bold',
      color: '#CF6790',
      fontSize: '24px',
      '@media (max-width:1280px)': {
        fontSize: '18px !important',
      },
    },
  },
  btn: {
    backgroundColor: 'primary',
    fontWeight: '700 !important',
    color: '#fff !important',
    marginRight: '1.5rem',
    '@media (max-width:1280px)': {
      fontSize: '0.6rem',
    },
  },
  flexgrow: {
    display: 'flex',
    flexGrow: '1',
  },
  logo: {
    width: '160px',
    '@media (max-width:1280px)': {
      width: '120px',
    },
  },
  root: {
    position: 'fixed',
    margin: '0 50px 50px 0',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

// Function for the scroll to top function, check if we need all code?
function ScrollTop(props) {
  const { children } = props;
  const classes = useClasses(styles);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

function Navbar() {
  const classes = useClasses(styles);

  return (
    <>
      <AppBar className={classes.navbar} elevation={10}>
        <Toolbar>
          <NavLink to="/" className={classes.flexgrow}>
            <img className={classes.logo} src={logo} alt="geshdo logo" />
          </NavLink>
          <div className={classes.flexgrow}>
            <NavLink to="/createpoll" className={classes.link}>
              Create Poll
            </NavLink>
            <NavLink to="/personalpolls" className={classes.link}>
              Personal Polls
            </NavLink>
            <NavLink to="/publicpolls" className={classes.link}>
              Public Polls
            </NavLink>
          </div>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => authContext.logOut()}
          >
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <ScrollTop>
        <Fab
          color="secondary"
          size="small"
          aria-label="scroll back to top"
          className={classes.circle}
        >
          <KeyboardArrowUpIcon className={classes.upIcon} />
        </Fab>
      </ScrollTop>
    </>
  );
}

export default Navbar;
